import React from 'react';

const headerStyles = {
    position: 'relative',
    padding: 'calc(var(--spacer) / 2)',
    paddingTop: '40vh',
    minHeight: 'calc(95vh - var(--spacer))',
    maxHeight: '1000px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
};

const Header = ({ children }) => (
    <header style={headerStyles}>{children}</header>
);

export default Header;
