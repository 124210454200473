import React from 'react';
import '../styles/global.css';
import SEO from '../components/SEO';
import ThemeToggle from '../components/ThemeToggle';
import Border from '../components/Border';
import Header from '../components/Header';
import Logo from '../components/Logo';
import Social from '../components/Social';

const IndexPage = () => (
    <Border>
        <SEO />
        <Header>
            <ThemeToggle />
            <Logo />
            <Social />
        </Header>
        <main>{/* TODO: use hero-pattern as spacer */}</main>
    </Border>
);

export default IndexPage;
