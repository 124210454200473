import { useState, useEffect } from 'react';

const getDarkMode = () =>
    typeof window === 'object' &&
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;

export default function useDarkMode() {
    const [darkMode, setDarkMode] = useState(getDarkMode());

    useEffect(() => {
        if (typeof window !== 'object' || process.env.NODE_ENV === 'test')
            return;

        function handleSystemThemeChange(e) {
            console.log(e);
            setDarkMode(e.matches);
        }

        try {
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .addEventListener('change', handleSystemThemeChange);
        } catch (err) {
            console.error(err);
        }

        return () =>
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .removeEventListener('change', handleSystemThemeChange);
    }, []);

    return { value: darkMode, toggle: () => setDarkMode(!darkMode) };
}
