import React from 'react';
import { Helmet } from 'react-helmet';

const seo = {
    title: 'Curtis Updike',
    description:
        'The personal website of Curtis Updike, a web developer in Indianapolis, IN.',
};

const SEO = () => (
    <Helmet
        title={seo.title}
        htmlAttributes={{
            lang: 'en',
        }}
    >
        <meta name="description" content={seo.description} />
    </Helmet>
);

export default SEO;
