import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { link, title, networks } from './Social.module.css';

const email = 'curtis@updike.dev';
const profiles = [
    {
        network: 'GitHub',
        url: 'https://github.com/curtisupdike',
    },
    {
        network: 'LinkedIn',
        url: 'https://www.linkedin.com/in/curtis-updike/',
    },
];

const SocialLink = ({ name, url }) => (
    <a
        className={name === 'Email' ? `u-email ${link}` : `u-url ${link}`}
        href={url}
        data-testid={`network-${name.toLowerCase()}`}
    >
        <Icon name={name} />
        <span className={title}>{name}</span>
    </a>
);

SocialLink.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

const Social = () => (
    <aside className={networks}>
        <SocialLink name="Email" url={`mailto:${email}`} />

        {profiles.map(profile => (
            <SocialLink
                key={profile.network}
                name={profile.network}
                url={profile.url}
            />
        ))}
    </aside>
);

export default Social;
