import React from 'react';
import PropTypes from 'prop-types';
import { Sun, Moon, Mail, GitHub, Linkedin } from 'react-feather';
import { icon } from './Icon.module.css';

Icon.propTypes = {
    name: PropTypes.string.isRequired,
};

export default function Icon({ name, ...props }) {
    const components = {
        Sun,
        Moon,
        Email: Mail,
        GitHub,
        LinkedIn: Linkedin,
    };

    const IconMapped = components[name];
    if (!IconMapped) return null;
    return <IconMapped className={icon} {...props} />;
}
