import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Icon from './Icon';
import {
    checkboxContainer,
    checkboxFake,
    themeSwitch,
    checkbox,
    label,
} from './ThemeToggle.module.css';
import useDarkMode from '../hooks/useDarkMode';

const ToggleButton = ({ darkMode }) => (
    <span id="toggle" className={checkboxContainer} aria-live="assertive">
        <Icon name="Sun" className={!darkMode ? null : 'active'} />
        <span className={checkboxFake} />
        <Icon name="Moon" className={darkMode ? 'active' : null} />
    </span>
);

ToggleButton.propTypes = {
    darkMode: PropTypes.bool.isRequired,
};

const Input = ({ darkMode, toggleDarkMode }) => (
    <input
        onChange={() => toggleDarkMode()}
        type="checkbox"
        name="themeToggle"
        value="themeToggle"
        aria-describedby="themeToggle"
        checked={darkMode}
        id="themeToggle"
    />
);

Input.propTypes = {
    darkMode: PropTypes.bool.isRequired,
    toggleDarkMode: PropTypes.func.isRequired,
};

export default function ThemeSwitch() {
    const { value, toggle } = useDarkMode();

    return (
        <>
            <Helmet>
                <body className={value ? 'dark' : null} />
            </Helmet>
            <aside className={themeSwitch}>
                <label className={checkbox} htmlFor="themeToggle">
                    <span className={label}>Toggle Night Mode</span>
                    <Input darkMode={value} toggleDarkMode={toggle} />
                    <ToggleButton darkMode={value} />
                </label>
            </aside>
        </>
    );
}
