import React from 'react';
import { Link } from 'gatsby';
import { logo, title, description } from './Logo.module.css';

const info = {
    name: 'Curtis Updike',
    role: 'Web Developer',
};

const Logo = () => (
    <Link className={logo} to={'/'}>
        <h1 className={`p-name ${title}`}>{info.name.toLowerCase()}</h1>
        <p className={`p-job-title ${description}`}>
            {info.role.toLowerCase()}
        </p>
    </Link>
);

export default Logo;
